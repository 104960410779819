import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

function SubscriptionOptions() {
  const navigate = useNavigate();

  const [subscriptions, setSubscriptions] = useState([
    {
      id: "1",
      name: "Starter Showcase",
      duration: "1",
      price: 55, // Keep this as a number for calculations
      subscriptionType: "Starter Showcase",
      features: [
        "Up to 25 vehicle listings",
        "Get 2 Weeks Free Membership Trial",
        "Logo display on listings",
        "1 Featured listing (rotated weekly)",
        "1 Social media boost per month (Facebook & Instagram)",
        "Similar listings feature",
        "Personalized dashboard",
      ],
    },
    {
      id: "2",
      name: "Growth Accelerator",
      duration: "1",
      price: 250,
      subscriptionType: "Growth Accelerator",
      features: [
        "Up to 75 vehicle listings",
        "Get 2 Weeks Free Membership Trial",
        "Logo display on listings",
        "3 Featured listings (rotated weekly)",
        "2 Social media boosts per month (Facebook & Instagram)",
        "Similar listings feature",
        "Personalized dashboard",
        "Priority listing placement",
        "Monthly performance report",
      ],
    },
    {
      id: "3",
      name: "Dealership Dominator",
      duration: "1",
      price: 400,
      subscriptionType: "Dealership Dominator",
      features: [
        "Logo display on listings and homepage carousel with direct link to dealership's page on BuySellCars",
        "Get 2 Weeks Free Membership Trial",
        "5 Featured listings (rotated weekly)",
        "3 Social media boosts per month (Facebook & Instagram)",
        "Similar listings feature",
        "Personalized dashboard",
        "Top priority listing placement",
        "CTR links to dealer's website (if applicable)",
        "Monthly performance report",
      ],
    },
  ]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
    }
  }, []);

  const [paymentLink, setPaymentLink] = useState("");
  const [duration, setDuration] = useState("1 Month");

  // Helper function to calculate discounted price
  const calculateDiscountedPrice = (basePrice, duration) => {
    switch (duration) {
      case "3 Months":
        return (basePrice * 3 * 0.95).toFixed(2); // 5% discount
      case "6 Months":
        return (basePrice * 6 * 0.9).toFixed(2); // 10% discount

      case "12 Months":
        return (basePrice * 12 * 0.85).toFixed(2); // 10% discount
      default:
        return (basePrice * 1).toFixed(2); // No discount
    }
  };

  const handleContinue = async ({ amount, subscriptionType }) => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found in localStorage");
      navigate("/login");
      return;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/payment/pay`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": token,
          },
          body: JSON.stringify({ amount, subscriptionType }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        setPaymentLink(data.link);
        window.location.href = data.link;
      } else {
        alert("Failed to initialize payment.");
      }
    } catch (error) {
      console.error("Error initializing payment:", error);
      alert("An error occurred. Please try again.");
    }
  };

  return (
    <div className="bg-[url(./Images/car.png)] bg-no-repeat min-h-screen bg-cover bg-center flex items-center justify-center">
      <div className="mx-auto grid grid-cols-1  max-md:mt-4 md:grid-cols-3 items-center gap-8 px-4">
        {subscriptions.map((subscription) => {
          const discountedPrice = calculateDiscountedPrice(
            subscription.price,
            duration
          );

          return (
            <div
              key={subscription.id}
              className="bg-white rounded-lg p-4 flex flex-col min-h-[480px] justify-between gap-8"
            >
              <div>
                <h4 className="font-bold text-xl">{subscription.name}</h4>
                <div className="flex justify-between items-center pt-2 pb-8">
                  <p className="text-2xl">
                    <span className="font-bold text-4xl">
                      {subscription.duration}
                    </span>
                    /mo
                  </p>
                  <p className="text-2xl">
                    <span className="font-bold text-4xl">$</span>
                    {discountedPrice}
                  </p>
                </div>
                <ul className="md:w-full max-w-2/3 flex flex-col gap-2">
                  {subscription.features.map((feature, index) => (
                    <li key={index} className="flex items-center gap-2">
                      <div className="w-4 h-4">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-4 w-4"
                          viewBox="0 0 512 512"
                        >
                          <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" />
                        </svg>
                      </div>
                      <p>{feature}</p>
                    </li>
                  ))}
                </ul>
                <h4 className="font-semibold text-[#d6bd83] mt-4 mb-2">
                  Select Duration for discount packages
                </h4>
                <select
                  name="duration"
                  id="duration"
                  value={duration}
                  onChange={(e) => setDuration(e.target.value)}
                  className="border border-solid border-zinc-400 rounded-lg p-2"
                >
                  <option value="1 Month">1 Month (No discount)</option>
                  <option value="3 Months">3 Months (5% discount)</option>
                  <option value="6 Months">6 Months (10% discount)</option>
                  <option value="12 Months">12 Months (15% discount)</option>
                </select>
              </div>
              <button
                onClick={() =>
                  handleContinue({
                    amount: discountedPrice,
                    subscriptionType: subscription.subscriptionType,
                  })
                }
                className="text-white text-center bg-[#d6bd83] font-semibold py-2 px-8 self-stretch rounded-lg text-lg"
              >
                Continue
              </button>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default SubscriptionOptions;
