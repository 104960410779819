import { useState, useEffect } from "react";
import { Star, Gauge, Fuel, Settings, Droplet, Tag } from "lucide-react"; // Updated Icons

function VehicleCard({ item }) {
  // Function to format price correctly with commas
  const formatPrice = (price) => {
    if (!price) return "N/A"; // Handle missing price case
    let numericPrice = price;

    if (typeof price === "string") {
      numericPrice = parseFloat(price.replace(/,/g, "")); // Remove commas if already present
    }

    if (isNaN(numericPrice)) return "Invalid Price"; // Handle invalid cases

    return numericPrice.toLocaleString(); // Add commas for thousands separator
  };

  return (
    <div className="
    w-full max-w-xs 
    sm:max-w-sm md:max-w-md 
    lg:max-w-lg xl:max-w-xl 
    shadow-xl 
    shadow-gray-300 
    h-full 
    rounded-3xl border border-gray-300 overflow-hidden flex flex-col flex-wrap bg-white">
      {/* Vehicle Image - Increased Height */}
      <figure className="relative w-full">
        <img
          src={`${process.env.REACT_APP_UPLOAD_URL}/${item.pictures[0]}`}
          alt={`${item.make} ${item.model}`}
          className="w-full h-72 sm:h-80 object-cover rounded-t-3xl"
        />

        {/* Year, Make, and Model - Positioned at Bottom-Left of Image with Background */}
        <div className="absolute bottom-0 left-0 bg-black bg-opacity-10 px-3 py-2 text-white">
          <p className="text-xs">{item?.year}</p>
          <p className="text-lg font-bold">{item?.make}</p>
          <p className="text-md">{item?.model}</p>
        </div>

        {/* Favorite Count */}
        <div className="absolute top-3 right-3 flex items-center bg-white px-3 py-1 rounded-md shadow-md">
          <Star className="w-5 h-5 text-yellow-500" />
          <span className="ml-1 text-sm font-semibold">{item.favorites || 0}</span>
        </div>
      </figure>

      {/* Lowered Main Box for a More Balanced Look */}
      <div className="p-5 space-y-4 flex flex-col flex-grow mt-[-10px]">
        {/* Price & Dealership Info (Aligned in a Row) */}
        <div className="flex justify-between items-center border-t border-gray-300 pt-3">
          <div className="text-sm flex flex-col items-center">
            <span className="flex items-center font-semibold text-[#d6bd83]">
              <Tag className="w-5 h-5 mr-1 text-[#d6bd83]" />
              Price
            </span>
            <p className="text-xl  font-bold">${formatPrice(item.price)}</p>
          </div>

          {/* Dealership Logo (Aligned with Price) */}
          {item?.user?.logo && (
            <img
              src={`${process.env.REACT_APP_UPLOAD_URL}/${item?.user?.logo[0]}`}
              alt="Dealership Logo"
              className="h-20 w-20  border-2 border-gray-400 shadow-md object-contain"
            />
          )}

        </div>

        {/* Vehicle Specifications - Center-aligned */}
        <div className="grid grid-cols-2 sm:grid-cols-3 gap-6 mt-4 text-sm border-t border-gray-300 pt-3 text-center">
          <div className="flex flex-col items-center">
            {/* 4CAF50 */}
            <Gauge className="w-6 h-6 mb-1" style={{ color: "#b98e35" }} />

            <span className="font-semibold text-gray-600">Condition</span>
            <span className="text-black">{item?.condition}</span>
          </div>
          <div className="flex flex-col items-center">
            {/* FF9800 */}
            <Fuel className="w-6 h-6 mb-1" style={{ color: "#b98e35" }} />
            <span className="font-semibold text-gray-600">Fuel Type</span>
            <span className="text-black">{item?.fuel}</span>
          </div>
          <div className="flex flex-col items-center">
            {/* 607D8B */}
            <Settings className="w-6 h-6 mb-1" style={{ color: "#b98e35" }} />
            <span className="font-semibold text-gray-600">Transmission</span>
            <span className="text-black">{item?.transmission}</span>
          </div>
          <div className="flex flex-col items-center">
            {/* 2196F3 */}
            <Gauge className="w-6 h-6 mb-1" style={{ color: "#b98e35" }} />
            <span className="font-semibold text-gray-600">Kilometers</span>
            <span className="text-black">{formatPrice(item?.mileage) ? `${formatPrice(item?.mileage)} km` : ""}</span>
          </div>
          <div className="flex flex-col items-center">
            {/* 9C27B0 */}
            <Droplet className="w-6 h-6 mb-1" style={{ color: "#b98e35" }} />
            <span className="font-semibold text-gray-600">Fuel Tank</span>
            <span className="text-black">{item?.fuelTank} L</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VehicleCard;
