import React from "react";
import Slider from "react-slick";
import styles from "../VehiclePage/VehiclePage.module.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Import images correctly
import carImage1 from "../../Images/indusBg.png"; // Ensure correct paths
import carImage2 from "../../Images/dealer.png";
import coverNew from '../../Images/covernew.jpg'
const CarsSlider = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false,
    fade: true,
  };

  // const slides = [carImage1, carImage2];
  const slides = [coverNew];

  return (
    <div className="">
      <Slider {...settings}>
        {slides.map((image, index) => (
          <div key={index} className="relative w-full min-h-[750px] flex items-center justify-center">
            {/* Background Image */}
            <div
              className="absolute inset-0 bg-center bg-no-repeat"
              style={{
                backgroundImage: `url(${image})`,
                backgroundSize: "cover", // Ensures full image visibility
                backgroundPosition: "center",
                height: "750px", // Ensures it fills the full slider height
                width: "100%",
              }}
            ></div>

            {/* Overlay to Make Text More Visible */}
            <div className="absolute inset-0 bg-black bg-opacity-50"></div>

            {/* Show Text ONLY on First Slide */}
            {index === 0 && (
              <div className={`${styles.carsMainHeading} absolute z-10  text-center w-full px-4`}>
                <div className={styles.carsHeading}>
                  <span style={{color:"#d6bd83"}}>
                  ZIMBABWE'S PREMIER VEHICLE MARKETPLACE
                    </span>
                     <br /> Revolutionizing How Zimbabwe Buys and Sells Vehicles
                </div>
                <div className={styles.carsText}>
                  <ul className="mt-3 mb-8 space-y-2">
                    <li>
                      <span className="text-green-400">&#10003;</span>
                      &nbsp;Launching a new era of digital automotive trading
                    </li>
                    <li>
                      <span className="text-green-400">&#10003;</span>
                      &nbsp;AI-powered matching for better deals
                    </li>
                    <li>
                      <span className="text-green-400">&#10003;</span>
                      &nbsp;Secure, transparent, and professional platform
                    </li>
                    <li>
                      <span className="text-green-400">&#10003;</span>
                      &nbsp;Simple 24/7 Digital Showcase
                    </li>
                  </ul>
                </div>
              </div>
            )}
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default CarsSlider;
