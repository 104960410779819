import React, { useEffect, useState } from "react";
import DealersPage from "./Pages/DealersPage/DealersPage";
import VehiclePage from "./Pages/VehiclePage/VehiclePage";
import BoatPage from "./Pages/BoatPage/BoatPage";
import BikePage from "./Pages/BikePage/BikePage";
import TruckPage from "./Pages/TruckPage/TruckPage";
import AgriculturePage from "./Pages/AgriculturePage/AgriculturePage";

import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./Components/Layout";
import yaris from "./Images/yaris.png";
import cuore from "./Images/cuore.png";
import sportage from "./Images/sportage.png";
import dealerLogo from "./Images/dealer-logo.png";
import InfoPage from "./Pages/InfoPage.jsx/InfoPage";
import Signup from "./Pages/Signup/Signup";
import Login from "./Pages/Login/Login";
import Form from "./Components/Common/Form";
import SubscriptionOptions from "./Pages/Subscription/SubscriptionOptions";
import SubscriptionPayment from "./Pages/Subscription/SubscriptionPayment";
import DashBoard from "./Pages/DashBoard/DashBoard";
import ScrollToTop from "./Components/Common/ScrollToTop";
import EditListing from "./Components/EditListing";
import EarthMovingPage from "./Pages/EarthMovingPage/EarthMovingPage";
import NewPage from "./Pages/NewPage/NewPage";
import UsedPage from "./Pages/UsedPage/UsedPage";
import CarRentalsPage from "./Pages/CarRentalsPage/CarRentalsPage";
import DealersDetailPage from "./Pages/DealersDetailPage/DealersDetailPage";
import Newsletter from "./Pages/newsLetter/NewsLetter";

const dealers = {
  featured: [
    {
      name: "AC's Wheels and Deals",
      contact: "0772270328",
      email: "cyprianos.anthony@gmail.com",
      location: "Harare",
      img: dealerLogo,
    },
    {
      name: "AC's Wheels and Deals",
      contact: "0772270328",
      email: "cyprianos.anthony@gmail.com",
      location: "Harare",
      img: dealerLogo,
    },
    {
      name: "AC's Wheels and Deals",
      contact: "0772270328",
      email: "cyprianos.anthony@gmail.com",
      location: "Harare",
      img: dealerLogo,
    },
  ],
  used: [
    {
      name: "AC's Wheels and Deals",
      contact: "0772270328",
      email: "cyprianos.anthony@gmail.com",
      location: "Harare",
      img: dealerLogo,
    },
    {
      name: "AC's Wheels and Deals",
      contact: "0772270328",
      email: "cyprianos.anthony@gmail.com",
      location: "Harare",
      img: dealerLogo,
    },
    {
      name: "AC's Wheels and Deals",
      contact: "0772270328",
      email: "cyprianos.anthony@gmail.com",
      location: "Harare",
      img: dealerLogo,
    },
    {
      name: "AC's Wheels and Deals",
      contact: "0772270328",
      email: "cyprianos.anthony@gmail.com",
      location: "Harare",
      img: dealerLogo,
    },
    {
      name: "AC's Wheels and Deals",
      contact: "0772270328",
      email: "cyprianos.anthony@gmail.com",
      location: "Harare",
      img: dealerLogo,
    },
    {
      name: "AC's Wheels and Deals",
      contact: "0772270328",
      email: "cyprianos.anthony@gmail.com",
      location: "Harare",
      img: dealerLogo,
    },
    {
      name: "AC's Wheels and Deals",
      contact: "0772270328",
      email: "cyprianos.anthony@gmail.com",
      location: "Harare",
      img: dealerLogo,
    },
    {
      name: "AC's Wheels and Deals",
      contact: "0772270328",
      email: "cyprianos.anthony@gmail.com",
      location: "Harare",
      img: dealerLogo,
    },
    {
      name: "AC's Wheels and Deals",
      contact: "0772270328",
      email: "cyprianos.anthony@gmail.com",
      location: "Harare",
      img: dealerLogo,
    },
  ],
};

const cars = {
  type: "Cars",
  featured: [
    {
      name: "Toyota Yaris 2020",
      price: "13,000",
      location: "Harare",
      img: yaris,
    },
    {
      name: "Dihatsu Cuore 2007",
      price: "13,000",
      location: "Harare",
      img: cuore,
    },
    {
      name: "Kia Sportage 2021",
      price: "13,000",
      location: "Harare",
      img: sportage,
    },
  ],
  used: [
    {
      name: "Toyota Yaris 2020",
      price: "13,000",
      location: "Harare",
      img: yaris,
    },
    {
      name: "Dihatsu Cuore 2007",
      price: "13,000",
      location: "Harare",
      img: cuore,
    },
    {
      name: "Kia Sportage 2021",
      price: "13,000",
      location: "Harare",
      img: sportage,
    },
    {
      name: "Toyota Yaris 2020",
      price: "13,000",
      location: "Harare",
      img: yaris,
    },
    {
      name: "Dihatsu Cuore 2007",
      price: "13,000",
      location: "Harare",
      img: cuore,
    },
    {
      name: "Kia Sportage 2021",
      price: "13,000",
      location: "Harare",
      img: sportage,
    },
    {
      name: "Toyota Yaris 2020",
      price: "13,000",
      location: "Harare",
      img: yaris,
    },
    {
      name: "Dihatsu Cuore 2007",
      price: "13,000",
      location: "Harare",
      img: cuore,
    },
    {
      name: "Kia Sportage 2021",
      price: "13,000",
      location: "Harare",
      img: sportage,
    },
  ],
};

// can pass one of either dealers or any vehicles as prop. passing both will render both dealers and vehicles on same page

// console.log = () => {};
// console.warn = () => {};
// console.error = () => {};
// console.info = () => {};
// console.debug = () => {};


const App = () => {

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        {/* cars */}
        <Route
          path="/cars/:id"
          element={
            <Layout dark={true}>
              <InfoPage />
            </Layout>
          }
        />

        <Route
          path="/"
          element={
            <Layout>
              <VehiclePage bg="bg-[url(./Images/covernew.jpg)]" />
            </Layout>
          }
        />

        {/* boats */}
        <Route
          path="/boats"
          element={
            <Layout>
              <BoatPage bg="bg-[url(./Images/covernew.jpg)]" />
            </Layout>
          }
        />

        <Route
          path="/bikes"
          element={
            <Layout>
              <BikePage bg="bg-[url(./Images/covernew.jpg)]" />
            </Layout>
          }
        />

        <Route
          path="/trucks"
          element={
            <Layout>
              <TruckPage bg="bg-[url(./Images/covernew.jpg)]" />
            </Layout>
          }
        />

        <Route
          path="/agriculture"
          element={
            <Layout>
              <AgriculturePage bg="bg-[url(./Images/covernew.jpg)]" />
            </Layout>
          }
        />

        <Route
          path="/earth-moving"
          element={
            <Layout>
              <EarthMovingPage bg="bg-[url(./Images/covernew.jpg)]" />
            </Layout>
          }
        />

        <Route
          path="/new"
          element={
            <Layout>
              <NewPage bg="bg-[url(./Images/covernew.jpg)]" />
            </Layout>
          }
        />

        <Route
          path="/used"
          element={
            <Layout>
              <UsedPage bg="bg-[url(./Images/covernew.jpg)]" />
            </Layout>
          }
        />

        <Route
          path="/car-rentals"
          element={
            <Layout>
              <CarRentalsPage bg="bg-[url(./Images/covernew.jpg)]" />
            </Layout>
          }
        />

        <Route
          path="/dealers"
          element={
            <Layout>
              <DealersPage
                bg="bg-[url(./Images/covernew.jpg)]"
                dealers={dealers}
              />
            </Layout>
          }
        />
        <Route
          path="/signup"
          element={
            <Form>
              <Signup />
            </Form>
          }
        />
        <Route
          path="/login"
          element={
            <Form>
              <Login />
            </Form>
          }
        />
        <Route path="/subscriptions" element={<SubscriptionOptions />} />
        <Route path="/subscription-payment" element={<SubscriptionPayment />} />
        <Route
          path="/dashboard"
          element={
            <Layout dark={true}>
              <DashBoard vehicles={cars} />
            </Layout>
          }
        />
        <Route
          path="/edit-listing/:vehicleId"
          element={
            <Layout dark={true}>
              <EditListing />
            </Layout>
          }
        />
        <Route
          path="/dealers/:dealerId"
          element={
            <Layout dark={true}>
              <DealersDetailPage />
            </Layout>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};
export default App;
