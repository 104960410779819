import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";

function DashboardAddListing({ vehicles }) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const currentYear = new Date().getFullYear();
  const startYear = 1950;
  const years = [];

  for (let year = startYear; year <= currentYear; year++) {
    years.push(year);
  }

  const [formData, setFormData] = useState({
    make: "",
    model: "",
    year: "",
    fuel: "",
    mileage: "",
    transmission: "",
    category: "",
    condition: "",
    price: "",
    description: "",
    pictures: [],
    registration: [],
  });

  const [error, setError] = useState("");
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [isLimitExceeded, setIsLimitExceeded] = useState(false);
  const [remainingListings, setRemainingListings] = useState(null);
  const [uploadedListings, setUploadedListings] = useState(0);
  const [totalLimit, setTotalLimit] = useState(null);



  useEffect(() => {
    if (!vehicles || vehicles.length === 0 || !vehicles[0]?.user) {
      setTotalLimit(null);
      setUploadedListings(0);
      setRemainingListings(null);
      setIsLimitExceeded(false);
      return;
    }

    let limit = null;
    let uploaded = vehicles.length;
    let userCategory = vehicles[0]?.user?.category || "";
    let subscriptionType = vehicles[0]?.user?.subscriptionType || "";

    console.log("User category:", userCategory);
    console.log("Subscription type:", subscriptionType);
    console.log("Uploaded:", uploaded);

    if (userCategory === "individual") {
      limit = 2;
    } else if (subscriptionType === "Starter Showcase") {
      limit = 25;
    } else if (subscriptionType === "Growth Accelerator") {
      limit = 75;
    }

    console.log("Limit:", limit);

    if (limit !== null) {
      setTotalLimit(limit);
      setUploadedListings(uploaded);
      setRemainingListings(limit - uploaded);
      setIsLimitExceeded(uploaded >= limit);
    } else {
      setTotalLimit(null);
      setUploadedListings(uploaded);
      setRemainingListings(null);
      setIsLimitExceeded(false);
    }
  }, [vehicles]);

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    let trimmedValue = typeof value === "string" ? value.trim() : value;

    setFormData((prevData) => {
      if (files) {
        return {
          ...prevData,
          [name]: [...prevData[name], ...files],
        };
      }
      return {
        ...prevData,
        [name]: trimmedValue,
      };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const token = localStorage.getItem("token");

    if (!token) {
      setError("No token found. Please login.");
      setShowErrorModal(true);
      navigate("/login");
      setLoading(false);
      return;
    }

    if (isLimitExceeded) {
      setError("You have reached your listing limit.");
      setShowErrorModal(true);
      setLoading(false);
      return;
    }

    for (const key in formData) {
      if (key !== "registration" && formData[key] === "") {
        setError(`Please fill in the ${key} field.`);
        setShowErrorModal(true);
        setLoading(false);
        return;
      }
    }

    const form = new FormData();
    for (const key in formData) {
      if (key === "pictures" || key === "registration") {
        formData[key].forEach((file) => {
          form.append(key, file);
        });
      } else {
        form.append(key, formData[key]);
      }
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/listing/add-listing`,
        {
          method: "POST",
          headers: {
            "x-auth-token": token,
          },
          body: form,
        }
      );

      if (response.ok) {
        alert("Listing added successfully!");
        window.location.reload();
      } else {
        const errorData = await response.json();
        setError(errorData.message);
        setShowErrorModal(true);
      }
    } catch (error) {
      setError("An error occurred. Please try again.");
      setShowErrorModal(true);
    }
    setLoading(false);
  };

  return (
    <div className="container">
       <div className="d-flex align-items-center justify-content-between mb-3 pb-3">
        <h2 className="fw-semibold fs-4">List Your Vehicle</h2>
        {totalLimit !== null && (
          <span
            className={`fw-bold ${
              isLimitExceeded ? "text-danger" : "text-success"
            }`}
          >
            ({uploadedListings} / {totalLimit})
          </span>
        )}
      </div>
      {isLimitExceeded && (
        <p className="text-danger fw-semibold">
          You have reached your listing limit. Upgrade your subscription to add more listings.
        </p>
      )}

      <form onSubmit={handleSubmit} className="row g-3">
        {[
          { name: "make", type: "text", placeholder: "Make" },
          { name: "model", type: "text", placeholder: "Model" },
          { name: "location", type: "text", placeholder: "Location" },
          { name: "price", type: "number", placeholder: "Price" },
          { name: "mileage", type: "number", placeholder: "Mileage (e.g., 10000km)" },
        ].map((field, index) => (
          <div className="col-md-6" key={index}>
            <input
              type={field.type}
              name={field.name}
              placeholder={field.placeholder}
              value={formData[field.name]}
              onChange={handleChange}
              className="form-control"
              required
            />
          </div>
        ))}
         <div className="col-md-6">
          <select name="condition" className="form-select" required value={formData.condition} onChange={handleChange}>
            <option value="">Select Condition</option>
            <option value="New">New</option>
            <option value="Used">Used</option>
          </select>
        </div>

        <div className="col-md-6">
          <select name="year" className="form-select" required onChange={handleChange}>
            <option value="">Select Year</option>
            {years.map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
        </div>

        {[
          { name: "fuel", options: ["Petrol", "Diesel", "Electric"] },
          { name: "transmission", options: ["Manual", "Automatic"] },
          {
            name: "category",
            options: [
              "Cars",
              "Boats",
              "Bikes",
              "Trucks",
              "Agriculture",
              "Earth Moving",
              "New",
              "Used",
              "Car Rentals",
            ],
          },
        ].map((selectField, index) => (
          <div className="col-md-6" key={index}>
            <select name={selectField.name} className="form-select" required onChange={handleChange}>
              <option value="">Select {selectField.name.charAt(0).toUpperCase() + selectField.name.slice(1)}</option>
              {selectField.options.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
        ))}

        <div className="col-12">
          <textarea
            name="description"
            rows="3"
            className="form-control"
            placeholder="Describe the vehicle..."
            value={formData.description}
            onChange={handleChange}
            required
          />
        </div>

        <div className="col-md-6">
          <label className="form-label">Upload Pictures</label>
          <input
            type="file"
            name="pictures"
            multiple
            className="form-control"
            onChange={handleChange}
            required
          />
        </div>

        <div className="col-md-6">
          <label className="form-label">Upload Spec Sheet (Optional)</label>
          <input
            type="file"
            name="registration"
            multiple
            accept="application/pdf"
            className="form-control"
            onChange={handleChange}
          />
        </div>

        <div className="col-12">
          <button
            type="submit"
            className={` text-white text-center w-100 ${
              !loading ? "bg-[#d6bd83]" : "bg-gray-700"
            } font-semibold py-2 px-8 self-center justify-self-center col-span-2 rounded-md text-lg mt-12 cursor-pointer`}
          
            disabled={loading || isLimitExceeded}
          >
            {loading ? "Submitting..." : "Submit"}
          </button>
        </div>
      </form>

      <Modal show={showErrorModal} onHide={() => setShowErrorModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Error</Modal.Title>
        </Modal.Header>
        <Modal.Body>{error}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowErrorModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default DashboardAddListing;
