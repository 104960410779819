import DashboardPerformanceTable from "./Common/DashboardPerformanceTable";
import { IoConstructOutline } from "react-icons/io5";

const performanceMetrics = [
  {
    name: "Enquiries",
    count: 3,
    icon: (
      <figure className="h-16 w-16 p-3 bg-[#E6FF093B] rounded-full flex items-center justify-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-full fill-[#93A30A]"
          viewBox="0 0 448 512"
        >
          <path d="M0 80L0 229.5c0 17 6.7 33.3 18.7 45.3l176 176c25 25 65.5 25 90.5 0L418.7 317.3c25-25 25-65.5 0-90.5l-176-176c-12-12-28.3-18.7-45.3-18.7L48 32C21.5 32 0 53.5 0 80zm112 32a32 32 0 1 1 0 64 32 32 0 1 1 0-64z" />
        </svg>
      </figure>
    ),
  },
];

function DashboardPerformance({ vehicles }) {
  console.log("vehiclesPerformance", vehicles);
  return (
    <div className="max-w-[1200px]">
      <h2 className="font-semibold text-2xl mb-4">Performance</h2>
      <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        <div className="flex items-center gap-3 border border-solid border-zinc-600 rounded-xl p-3">
          <figure className="h-16 w-16 p-3 bg-[#FFA9093B] rounded-full flex items-center justify-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-full fill-[#FFA909]"
              viewBox="0 0 640 512"
            >
              <path d="M171.3 96L224 96l0 96-112.7 0 30.4-75.9C146.5 104 158.2 96 171.3 96zM272 192l0-96 81.2 0c9.7 0 18.9 4.4 25 12l67.2 84L272 192zm256.2 1L428.2 68c-18.2-22.8-45.8-36-75-36L171.3 32c-39.3 0-74.6 23.9-89.1 60.3L40.6 196.4C16.8 205.8 0 228.9 0 256L0 368c0 17.7 14.3 32 32 32l33.3 0c7.6 45.4 47.1 80 94.7 80s87.1-34.6 94.7-80l130.7 0c7.6 45.4 47.1 80 94.7 80s87.1-34.6 94.7-80l33.3 0c17.7 0 32-14.3 32-32l0-48c0-65.2-48.8-119-111.8-127zM434.7 368a48 48 0 1 1 90.5 32 48 48 0 1 1 -90.5-32zM160 336a48 48 0 1 1 0 96 48 48 0 1 1 0-96z" />
            </svg>
          </figure>
          <div>
            <p className="font-extrabold text-4xl">{vehicles?.length}</p>
            <p className="text-xl text-[#030229]">Total Vehicle</p>
          </div>
        </div>
        <div className="flex items-center gap-3 border border-solid border-zinc-600 rounded-xl p-3">
          <figure className="h-16 w-16 p-3 bg-[#42FF093B] rounded-full">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-9 fill-[#37D806]"
              viewBox="0 0 576 512"
            >
              <path d="M248 106.6c18.9-9 32-28.3 32-50.6c0-30.9-25.1-56-56-56s-56 25.1-56 56c0 22.3 13.1 41.6 32 50.6l0 98.8c-2.8 1.3-5.5 2.9-8 4.7l-80.1-45.8c1.6-20.8-8.6-41.6-27.9-52.8C57.2 96 23 105.2 7.5 132S1.2 193 28 208.5c1.3 .8 2.6 1.5 4 2.1l0 90.8c-1.3 .6-2.7 1.3-4 2.1C1.2 319-8 353.2 7.5 380S57.2 416 84 400.5c19.3-11.1 29.4-32 27.8-52.8l50.5-28.9c-11.5-11.2-19.9-25.6-23.8-41.7L88 306.1c-2.6-1.8-5.2-3.3-8-4.7l0-90.8c2.8-1.3 5.5-2.9 8-4.7l80.1 45.8c-.1 1.4-.2 2.8-.2 4.3c0 22.3 13.1 41.6 32 50.6l0 98.8c-18.9 9-32 28.3-32 50.6c0 30.9 25.1 56 56 56c30.7 0 55.6-24.7 56-55.2c-7.5-12.9-13.5-26.8-17.6-41.5c-4.2-4-9.1-7.3-14.4-9.9l0-98.8c2.8-1.3 5.5-2.9 8-4.7l10.5 6c5.5-15.3 13.1-29.5 22.4-42.5l-9.1-5.2c.1-1.4 .2-2.8 .2-4.3c0-22.3-13.1-41.6-32-50.6l0-98.8zM440.5 132C425 105.2 390.8 96 364 111.5c-19.3 11.1-29.4 32-27.8 52.8l-50.6 28.9c11.5 11.2 19.9 25.6 23.8 41.7L360 205.9c.4 .3 .8 .6 1.3 .9c21.7-9.5 45.6-14.8 70.8-14.8c2 0 4 0 5.9 .1c12.1-17.3 13.8-40.6 2.6-60.1zM432 512a144 144 0 1 0 0-288 144 144 0 1 0 0 288zm47.9-225c4.3 3.7 5.4 9.9 2.6 14.9L452.4 356l35.6 0c5.2 0 9.8 3.3 11.4 8.2s-.1 10.3-4.2 13.4l-96 72c-4.5 3.4-10.8 3.2-15.1-.6s-5.4-9.9-2.6-14.9L411.6 380 376 380c-5.2 0-9.8-3.3-11.4-8.2s.1-10.3 4.2-13.4l96-72c4.5-3.4 10.8-3.2 15.1 .6z" />
            </svg>
          </figure>
          <div>
            <p className="font-extrabold text-4xl">
              {" "}
              {
                vehicles?.filter((vehicle) => vehicle.status === "Pending")
                  ?.length
              }
            </p>
            <p className="text-xl text-[#030229]">Active Vehicle</p>
          </div>
        </div>
        <div className="flex items-center gap-3 border border-solid border-zinc-600 rounded-xl p-3">
          <figure className="h-16 w-16 p-3 bg-[#09FFF73B] rounded-full flex items-center justify-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-full fill-[#06AED8]"
              viewBox="0 0 576 512"
            >
              <path d="M64 32C28.7 32 0 60.7 0 96l0 32 576 0 0-32c0-35.3-28.7-64-64-64L64 32zM576 224L0 224 0 416c0 35.3 28.7 64 64 64l448 0c35.3 0 64-28.7 64-64l0-192zM112 352l64 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-64 0c-8.8 0-16-7.2-16-16s7.2-16 16-16zm112 16c0-8.8 7.2-16 16-16l128 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-128 0c-8.8 0-16-7.2-16-16z" />
            </svg>
          </figure>
          <div>
            <p className="font-extrabold text-4xl">
              {vehicles?.filter((vehicle) => vehicle.featured)?.length}
            </p>
            <p className="text-xl text-[#030229]">Featured Vehicle</p>
          </div>
        </div>
        <div className="flex items-center gap-3 border border-solid border-zinc-600 rounded-xl p-3">
          <figure className="h-16 w-16 p-3 bg-[#E6FF093B] rounded-full flex items-center justify-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-full fill-[#93A30A]"
              viewBox="0 0 448 512"
            >
              <path d="M0 80L0 229.5c0 17 6.7 33.3 18.7 45.3l176 176c25 25 65.5 25 90.5 0L418.7 317.3c25-25 25-65.5 0-90.5l-176-176c-12-12-28.3-18.7-45.3-18.7L48 32C21.5 32 0 53.5 0 80zm112 32a32 32 0 1 1 0 64 32 32 0 1 1 0-64z" />
            </svg>
          </figure>
          <div>
            <p className="font-extrabold text-4xl">0</p>
            <p className="text-xl text-[#030229]">Inquiries</p>
          </div>
        </div>
      </div>

      <DashboardPerformanceTable vehicles={vehicles} />

      {/* <h2 className="font-semibold text-2xl mt-4">Performance Video Ads</h2>
      <div className="bg-[url(./Images/bulldozer.png)] bg-center bg-cover bg-no-repeat h-80 my-4"></div>
      <div className="flex gap-4 mb-16">
        <div className="border border-solid border-zinc-600 rounded-xl basis-full">
          <div className="flex flex-col items-center p-3">
            <p className="font-extrabold text-4xl">3</p>
            <p className="text-xl text-[#030229]">T.Views</p>
          </div>
        </div>
        <div className="border border-solid border-zinc-600 rounded-xl basis-full">
          <div className="flex flex-col items-center p-3">
            <p className="font-extrabold text-4xl">$3</p>
            <p className="text-xl text-[#030229]">T.Spent Budget</p>
          </div>
        </div>
        <div className="border border-solid border-zinc-600 rounded-xl basis-full">
          <div className="flex flex-col items-center p-3">
            <p className="font-extrabold text-4xl">3</p>
            <p className="text-xl text-[#030229]">T.Views</p>
          </div>
        </div>
      </div> */}
    </div>
    // <div className="h-screen content-center self-center text-center justify-center items-center">
    //   <p className="text-3xl text-[#d6bd83] font-semibold">
    //     Coming Soon Next Week
    //   </p>
    //   <div className="flex justify-center mt-6">
    //     <IoConstructOutline className="text-6xl text-[#d6bd83]" />
    //   </div>
    // </div>
  );
}

export default DashboardPerformance;
