import React from "react";
import styles from "./Newsletter.module.css";
import logo from "../../Images/newsLogo.png"; // Import your logo
import background from "../../Images/racing.jpg"; // Import your background image

const Newsletter = ({ onClose }) => {
  return (
    <div className={styles.modalOverlay} >
      <div
       className={styles.modalContent} 
       style={{ 
        backgroundImage: `url(${background})`, 
        backgroundSize: 'cover',  // Ensures it fills the entire div
        backgroundPosition: 'center', 
        backgroundRepeat: 'no-repeat', 
        // minHeight: '100vh', // Ensures it covers the full screen height
        width: '100%', // Ensures it spans the full width of the div
        margin:"10px"
      }}
      
      >
        <div className={styles.modalHeader} >
          <div
          >
          <img src={logo} alt="BuySellCars" className={styles.logo} />

          </div>

          <button className={styles.closeButton} onClick={onClose}>X</button>
        </div>

    <div style={{position:"relative",top:"-105px",color:"#d6bd83",    textShadow: "2px 2px 3px black",}}>
        <h2 className={styles.title}>Stay Updated</h2>
        <p className={styles.description}>
          Subscribe to our newsletter and stay up to date with the latest buy sell cars news.
        </p>
    </div>
        <div className={styles.inputContainer}>
          <input type="email" placeholder="Email Address" className={styles.emailInput} />
          <button className={styles.subscribeButton}>SUBSCRIBE</button>
        </div>
      </div>
    </div>
  );
};

export default Newsletter;
