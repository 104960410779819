import React, { useState, useEffect } from "react";
import axios from "axios";
import Slider from "react-slick";
import styles from "./VehiclePage.module.css";
import Search from "./Search";
import Sponsored from "../../Components/Common/Sponsored";
import Bulldozer from "../../Components/Common/Bulldozer";
import CallToAction from "../../Components/Common/CallToAction";
import VehicleList from "../../Components/Common/VehicleList";
import DealersList from "../../Components/Common/DealersList";
import { Audio } from "react-loader-spinner";
import { IoConstructOutline } from "react-icons/io5";
import Feedback from "../../Components/Common/Feedback";
import Newsletter from "../newsLetter/NewsLetter";
import CarsSlider from "../CarsSlider/CarsSlider";

const VehiclePage = ({ bg, dealers }) => {
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    arrows: false, // Hides the arrows
    responsive: [
      {
        breakpoint: 1370,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 940,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  var settings2 = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    initialSlide: 0,
    arrows: false, // Hides the arrows
    responsive: [
      {
        breakpoint: 1370,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 940,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [vehicles, setVehicles] = useState([]);
  const [featuredVehicles, setFeaturedVehicles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const [showModal, setShowModal] = useState(false);
  
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowModal(true);
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    console.log("length", vehicles?.length);
    console.log("working");

    fetchAllVehicles();
    fetchFeaturedVehicles();

    console.log("useEffecct", vehicles, featuredVehicles);
  }, []);

  console.log("normal", vehicles, featuredVehicles);

  const fetchAllVehicles = async () => {
    setLoading(true);
    setError("");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/listing/get-all-listings`
      );
      setVehicles(response?.data?.sortedVehicles);
      console.log("lalalal", response?.data?.sortedVehicles);
    } catch (err) {
      setError("Failed to fetch listings.");
    } finally {
      console.log("finally", vehicles);
      setLoading(false);
    }
  };

  const fetchFeaturedVehicles = async () => {
    setLoading(true);
    setError("");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/listing/get-featured-listings`
      );
      setFeaturedVehicles(response?.data?.data);
    } catch (err) {
      setError("Failed to fetch listings.");
    } finally {
      console.log("featured", featuredVehicles);
      setLoading(false);
    }
  };


  const settingsSliderMain = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false, // Hide arrows to keep the design clean
    fade: true, // Smooth fading transition
  };
  
  const images = [
    "../../Images/indusBg.png", // Replace with actual image paths
    "../../Images/dealer.png",
    "../../Images/carsBg.png",
  ];
  

  return loading ? (
    <div className="flex items-center h-screen justify-center">
      <Audio
        height="80"
        width="80"
        radius="9"
        color="rgb(185, 142, 53)"
        ariaLabel="loading"
        wrapperStyle
        wrapperClass
      />
    </div>
  ) : (
    <div>
        {showModal && <Newsletter onClose={() => setShowModal(false)} />} 

      <div className={styles.carsMain}>
        <CarsSlider />
        {/* <div className={`${bg} bg-center bg-cover bg-no-repeat `}>
          <div className={styles.carsMainHeading}>
            <div className={`${styles.carsHeading}`}>
              ZIMBABWE'S PREMIER VEHICLE MARKETPLACE <br /> Revolutionizing How
              Zimbabwe Buys and Sells Vehicles
            </div>
            <div className={styles.carsText}>
              <ul className="mt-3 mb-8 space-y-2">
                <li>
                  <span className="text-green-500">&#10003;</span>
                  &nbsp;Launching a new era of digital automotive trading
                </li>
                <li>
                  <span className="text-green-500">&#10003;</span>
                  &nbsp;AI-powered matching for better deals
                </li>
                <li>
                  <span className="text-green-500">&#10003;</span>
                  &nbsp;Secure, transparent, and professional platform
                </li>
                <li>
                  <span className="text-green-500">&#10003;</span>&nbsp;Simple
                  24/7 Digital Showcase
                </li>
              </ul>
            </div>
          </div>
        </div> */}

        {
          <VehicleList
            vehicles={vehicles}
            featuredVehicles={featuredVehicles}
          />
        }

        {/* Bulldozer component  */}
        <Bulldozer />

        {/* Sponsored Partners component  */}
        <Sponsored />

        {/* Industry component  */}
        <div
          className={styles.feedbackSliderMain}
          style={{ paddingLeft: 0, paddingRight: 0 }}
        >
          <Slider {...settings2}>
            <div className={`${styles.indusMain} w-full`}>
              <div className={`${styles.indusContainer} w-full`}>
                <div className={styles.indusHeadingMain}>
                  <div className={styles.indusHeading}>
                  <span style={{color:"#d6bd83"}}>
                    JOIN THE FUTURE OF VEHICLE TRADING
                    <br />
                    Your Success is Our Priority
                  </span>
                  </div>
                  <ul className="mt-3 mb-8 space-y-2 text-white">
                    <li>
                      <span className="text-green-500">&#10003;</span>
                      &nbsp;Expert support at every step
                    </li>
                    <li>
                      <span className="text-green-500">&#10003;</span>
                      &nbsp;Market leading visibility
                    </li>
                    <li>
                      <span className="text-green-500">&#10003;</span>
                      &nbsp;Secure transaction guidance
                    </li>
                    <li>
                      <span className="text-green-500">&#10003;</span>
                      &nbsp;Professional listing tools
                    </li>
                    <li>
                      <span className="text-green-500">&#10003;</span>
                      &nbsp;Access to verified buyers and premium sellers
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className={`${styles.indusMain} w-full`}>
              <div className={`${styles.indusContainer} w-full`}>
                <div className={styles.indusHeadingMain}>
                  <div className={styles.indusHeading}>
                    JOIN THE FUTURE OF VEHICLE TRADING
                    <br />
                    Your Success is Our Priority
                  </div>
                  <ul className="mt-3 mb-8 space-y-2 text-white">
                    <li>
                      <span className="text-green-500">&#10003;</span>
                      &nbsp;Expert support at every step
                    </li>
                    <li>
                      <span className="text-green-500">&#10003;</span>
                      &nbsp;Market leading visibility
                    </li>
                    <li>
                      <span className="text-green-500">&#10003;</span>
                      &nbsp;Secure transaction guidance
                    </li>
                    <li>
                      <span className="text-green-500">&#10003;</span>
                      &nbsp;Professional listing tools
                    </li>
                    <li>
                      <span className="text-green-500">&#10003;</span>
                      &nbsp;Access to verified buyers and premium sellers
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className={`${styles.indusMain} w-full`}>
              <div className={`${styles.indusContainer} w-full`}>
                <div className={styles.indusHeadingMain}>
                  <div className={styles.indusHeading}>
                    JOIN THE FUTURE OF VEHICLE TRADING
                    <br />
                    Your Success is Our Priority
                  </div>
                  <ul className="mt-3 mb-8 space-y-2 text-white">
                    <li>
                      <span className="text-green-500">&#10003;</span>
                      &nbsp;Expert support at every step
                    </li>
                    <li>
                      <span className="text-green-500">&#10003;</span>
                      &nbsp;Market leading visibility
                    </li>
                    <li>
                      <span className="text-green-500">&#10003;</span>
                      &nbsp;Secure transaction guidance
                    </li>
                    <li>
                      <span className="text-green-500">&#10003;</span>
                      &nbsp;Professional listing tools
                    </li>
                    <li>
                      <span className="text-green-500">&#10003;</span>
                      &nbsp;Access to verified buyers and premium sellers
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </Slider>
        </div>

        {/* Feedback  */}
        <Feedback />
      </div>
    </div>
  );
};

export default VehiclePage;
