import { useState, useEffect } from "react";
import { SwiperSlide, Swiper } from "swiper/react";
import "swiper/css";
import axios from "axios";
import { Audio } from "react-loader-spinner";
import { Link, useNavigate } from "react-router-dom";
import { IoLogoWhatsapp } from "react-icons/io5";
import { FaFilePdf } from "react-icons/fa";
import VehicleCard from "../../Components/Common/VehicleCard";
import calendar from "../../Images/calendar.png";
import gear from "../../Images/gear.png";
import speedometer from "../../Images/speedometer.png";
import fuel from "../../Images/fuel.png";
import daihatsuCuore from "../../Images/daihatsu-cuore.png";

const user = "buyer";

const dummyVehicle = {
  make: "Toyota",
  model: "Corolla",
  year: "2015",
  price: "15,000",
  location: "Harare",
  fuel: "Petrol",
  mileage: "30,000 km",
  transmission: "Automatic",
  description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  pictures: [daihatsuCuore, daihatsuCuore, daihatsuCuore],
  user: {
    dealershipName: "Auto Dealers Ltd.",
    phoneNumber: "263776543210",
    logo: null,
  },
  registration: [],
};

function InfoPage({ item }) {
  const navigate = useNavigate();
  const [displayedImage, setDisplayedImage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [listing, setListing] = useState(null);
  const [vehicles, setVehicles] = useState([]);
  const formatPrice = (price) => {
    if (!price) return "N/A"; // Handle missing price case
    let numericPrice = price;

    if (typeof price === "string") {
      numericPrice = parseFloat(price.replace(/,/g, "")); // Remove commas if already present
    }

    if (isNaN(numericPrice)) return "Invalid Price"; // Handle invalid cases

    return numericPrice.toLocaleString(); // Add commas for thousands separator
  };
  // useEffect(() => {
  //   fetchListing();
  //   fetchAllVehicles();
  // }, []);
  useEffect(() => {
    console.log("ajkndkasnkdajks")
    window.scrollTo(0, 0);
    fetchListing();
    fetchAllVehicles();
  }, []);
  
  const fetchListing = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/listing/get-listing/${localStorage.getItem("carId")}`
      );
      setListing(response?.data?.listing || dummyVehicle);
    } catch {
      setListing(dummyVehicle);
    } finally {
      setLoading(false);
    }
  };

  const fetchAllVehicles = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/listing/get-all-listings`
      );
      setVehicles(response?.data?.sortedVehicles || []);
    } catch {
      setVehicles([]);
    }
  };

  const handleBuyer = () => {
    if (!localStorage.token) {
      alert("You need to sign up as a buyer first.");
      navigate("/login");
    }
  };

  return loading ? (
    <div className="flex items-center h-screen justify-center">
      <Audio height="80" width="80" color="rgb(185, 142, 53)" ariaLabel="loading" />
    </div>
  ) : (
    <div className="max-w-[1400px] mx-auto px-4 py-8">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <figure className="flex flex-col items-center">
          <img
            src={`${process.env.REACT_APP_UPLOAD_URL}/${listing?.pictures[displayedImage]}`}
            alt="Vehicle"
            className="rounded-md w-full object-cover md:h-[500px]"
          />
          <div className="flex gap-2 mt-4">
            {listing?.pictures?.map((picture, index) => (
              <img
                key={index}
                src={`${process.env.REACT_APP_UPLOAD_URL}/${picture}`}
                alt={`Thumbnail ${index + 1}`}
                onClick={() => setDisplayedImage(index)}
                className="w-16 h-16 rounded-md cursor-pointer"
              />
            ))}
          </div>
        </figure>

        <div>
          <h2 className="text-3xl font-semibold">{listing?.make} {listing?.model} {listing?.year}</h2>
          <p className="text-xl text-[#d6bd83] my-2">Price: ${formatPrice(listing?.price) || "N/A"}</p>
          <p className="text-lg">{listing?.description || "No description available."}</p>

          <div className="flex gap-4 mt-6">
            <div
              className="cursor-pointer"
              onClick={() => {
                navigate(`/dealers/${listing?.user?._id}`);
              }}
            >
              {listing?.user?.logo !== null ? (
                <img
                  src={`${process.env.REACT_APP_UPLOAD_URL}/${listing?.user?.logo[0]}`}
                  alt="logo"
                  className="h-28"
                />
              ) : (
                ""
              )}
            </div>


            <Link to={`https://wa.me/${listing?.user?.phoneNumber}`} target="_blank">
              <button className="bg-[#3EB549] p-3 rounded-md text-white flex items-center gap-2">
                <IoLogoWhatsapp className="text-xl" />
                Message
              </button>
            </Link>
            <Link to={`tel:${listing?.user?.phoneNumber}`} target="_blank">
              <button className="bg-[#3E6CB5] px-6 py-3 rounded-md text-white flex items-center gap-2">
                Call
              </button>
            </Link>

          </div>


          <div className="grid grid-cols-2 md:grid-cols-4 gap-6 border mt-6 p-4 rounded-md">
            {[{ icon: calendar, value: listing?.year }, { icon: fuel, value: listing?.fuel },
            { icon: speedometer, value: listing?.mileage }, { icon: gear, value: listing?.transmission }].map((item, idx) => (
              <div key={idx} className="text-center">
                <img src={item.icon} alt="Icon" className="w-10 mx-auto" />
                <p>{item.value || "N/A"}</p>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="mt-12">
        {/* <div>
        <div className="bg-white rounded-md p-6 mb-8 shadow-lg">
        <h3 className="text-2xl font-semibold mb-4">Highlights</h3>
        <ul className="list-disc pl-5 space-y-2 text-lg">
          <li>{listing?.highlights?.[0] || "Lorem ipsum dolor sit amet."}</li>
          <li>{listing?.highlights?.[1] || "Lorem ipsum dolor sit amet."}</li>
          <li>{listing?.highlights?.[2] || "Lorem ipsum dolor sit amet."}</li>
          <li>{listing?.highlights?.[3] || "Lorem ipsum dolor sit amet."}</li>
        </ul>
      </div>

      <div className="bg-white rounded-md p-6 mb-8 shadow-lg">
        <h3 className="text-2xl font-semibold mb-4">Technical Data</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="flex justify-between text-lg">
            <span className="font-semibold">Chassis No:</span>
            <span>{listing?.chassisNo || "N/A"}</span>
          </div>
          <div className="flex justify-between text-lg">
            <span className="font-semibold">Seller:</span>
            <span>{listing?.user?.dealershipName || "N/A"}</span>
          </div>
          <div className="flex justify-between text-lg">
            <span className="font-semibold">Seller Type:</span>
            <span>{listing?.user?.sellerType || "N/A"}</span>
          </div>
          <div className="flex justify-between text-lg">
            <span className="font-semibold">Interior:</span>
            <span>{listing?.interior || "N/A"}</span>
          </div>
          <div className="flex justify-between text-lg">
            <span className="font-semibold">Mileage:</span>
            <span>{listing?.mileage || "N/A"}</span>
          </div>
          <div className="flex justify-between text-lg">
            <span className="font-semibold">Vehicle Make / Model:</span>
            <span>{listing?.make} {listing?.model}</span>
          </div>
          <div className="flex justify-between text-lg">
            <span className="font-semibold">Location:</span>
            <span>{listing?.location || "N/A"}</span>
          </div>
          <div className="flex justify-between text-lg">
            <span className="font-semibold">Drive Orientation:</span>
            <span>{listing?.driveOrientation || "N/A"}</span>
          </div>
        </div>
      </div>
        </div> */}
        <h3 className="text-2xl font-bold mb-4">Similar Listings</h3>
        <Swiper
          spaceBetween={10}
          slidesPerView={1} // Default to 1 slide per view
          breakpoints={{
            640: { slidesPerView: 1 },
            768: { slidesPerView: 2 },
            1024: { slidesPerView: 3 },
          }}
        >
          {vehicles?.map((item) => (
            <SwiperSlide key={item._id}>
              <button
                onClick={() => {
                  localStorage.setItem("carId", item._id);
                  fetchListing(); 
                }}
              >
                <VehicleCard item={item} featured={false} />
              </button>
            </SwiperSlide>
          ))}
        </Swiper>

      </div>
    </div>
  );
}

export default InfoPage;
