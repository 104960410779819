import React, { useState } from "react";
import styles from "./VehiclePage.module.css";

const Search = ({ onSearch }) => {
  const currentYear = new Date().getFullYear();
  const startYear = 1950;
  const years = Array.from({ length: currentYear - startYear + 1 }, (_, i) => startYear + i);

  const [searchInputs, setSearchInputs] = useState({
    make: "",
    model: "",
    year: "",
    condition: "",
    type: "",
    priceFrom: "",
    priceTo: "",
    location: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSearchInputs((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSearch = () => {
    onSearch(searchInputs);
  };

  return (
    <div className={styles.searchMain}>
      <div className={styles.searchContainer}>
        {/* First Row */}
        <div className={styles.inputRow}>
          <input placeholder="Make" name="make" className={styles.searchInput} onChange={handleChange} />
          <input placeholder="Model" name="model" className={styles.searchInput} onChange={handleChange} />
          <select name="year" className={styles.searchInput} onChange={handleChange}>
            <option value="">Year</option>
            {years.map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
        </div>

        {/* Second Row */}
        <div className={styles.inputRow}>
          <input placeholder="Condition" name="condition" className={styles.searchInput} onChange={handleChange} />
          <input placeholder="Type" name="type" className={styles.searchInput} onChange={handleChange} />
          <input placeholder="Location" name="location" className={styles.searchInput} onChange={handleChange} />
        </div>

        {/* Third Row */}
        <div className={styles.inputRow}>
          <input type="number" placeholder="Price From" name="priceFrom" className={styles.searchInput} onChange={handleChange} />
          <input type="number" placeholder="Price To" name="priceTo" className={styles.searchInput} onChange={handleChange} />
        </div>

        {/* Search Button */}
        <div className={styles.searchBtnContainer}>
          <button className={styles.searchBtn} onClick={handleSearch}>
            Search
          </button>
        </div>
      </div>
    </div>
  );
};

export default Search;