import logoDark from "../../Images/logo-dark.png";
import card from "../../Images/card.png";
import { Link } from "react-router-dom";

function SubscriptionPayment() {
  const handleSubmit = function (e) {
    e.preventDefault();
  };

  return (
    <div className="bg-[url(./Images/car.png)] bg-no-repeat min-h-screen bg-cover bg-center flex items-center justify-center">
      <div className="bg-white rounded-lg p-6 w-full max-w-md mx-auto fixed">
        <div className="flex justify-between items-start">
          <img src={logoDark} alt="" />
          <Link
            to="/subscriptions"
            className="text-zinc-400 hover:text-zinc-500"
          >
            &#x2716;
          </Link>
        </div>

        <h3 className="font-bold text-2xl my-3">
          Enter Payment Details to Secure Your Listings
        </h3>

        <form onSubmit={handleSubmit} className="text-sm flex flex-col gap-3">
          <div className="flex items-center border border-solid border-zinc-600 rounded-md px-2">
            <div className="flex items-center gap-2 w-full">
              <label htmlFor="card-number">
                <img src={card} alt="" />
              </label>
              <input
                type="tel"
                id="card-number"
                name="card-number"
                pattern="[0-9]{4} [0-9]{4} [0-9]{4} [0-9]{4}"
                required
                className="w-full py-2 focus:outline-none"
                placeholder="4242 4242 4242 4242"
              />
            </div>
            <input
              type="month"
              id="month"
              name="month"
              min="2018-03"
              value="2018-05"
              className=""
            />
          </div>
          <input
            type="text"
            id="name"
            name="name"
            required
            minlength="4"
            maxlength="12"
            className="border border-solid border-zinc-600 rounded-md p-2 focus:outline-none"
            placeholder="NAME ON CARD"
          />
          <input
            type="tel"
            id="card-number"
            name="card-number"
            pattern="[0-9]{4}-[0-9]{7}"
            required
            className="border border-solid border-zinc-600 rounded-md p-2 focus:outline-none"
            placeholder="PHONE NUMBER"
          />
          <input
            type="text"
            id="address"
            name="address"
            required
            className="border border-solid border-zinc-600 rounded-md p-2 focus:outline-none"
            placeholder="ADDRESS"
          />
          <input
            type="number"
            id="postal-code"
            name="postal-code"
            required
            className="border border-solid border-zinc-600 rounded-md p-2 focus:outline-none"
            placeholder="POSTAL CODE"
          />
          <div className="flex justify-center">
            <Link to="/dashboard">
              <button className="text-white bg-[#d6bd83] font-semibold py-2 px-8 self-center rounded-md text-lg">
                Register & Bid
              </button>
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
}
export default SubscriptionPayment;
